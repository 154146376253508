<template>
  <RegistrationLayout>
    <template v-slot:header>
      <HMButton
        color="white"
        rounded="full"
        size="x-small"
        fontWeight="semibold"
        textSize="small"
        padding="tight"
        @click="back"
      >
        <span>
          <Icon iconClass="fas fa-chevron-left" /> {{ $t('common.back_btn') }}
        </span>
      </HMButton>
      <div />
    </template>
    <div class="md:w-435 md:mx-auto">
      <Title class="text-center">{{
        $t('account_recovery.forgot_username.title')
      }}</Title>
      <Form :validator="$v.model" :error="error">
        <FormInput
          v-model="model.firstName"
          :label="$t('account_recovery.forgot_username.first_name')"
          name="firstName"
          :placeholder="
            $t('account_recovery.forgot_username.first_name_place_holder')
          "
        />
        <FormInput
          v-model="model.lastName"
          class="mt-16"
          :label="$t('account_recovery.forgot_username.last_name')"
          name="lastName"
          :placeholder="
            $t('account_recovery.forgot_username.last_name_place_holder')
          "
        />
        <FormInput
          v-model="model.memberNumber"
          class="mt-16"
          :label="$t('account_recovery.forgot_username.member_number')"
          name="memberNumber"
          :placeholder="
            $t('account_recovery.forgot_username.member_number_place_holder')
          "
        />
        <FormDatePicker
          v-model="model.birthday"
          class="mt-16"
          :label="$t('account_recovery.forgot_username.birthday')"
          name="birthday"
          :maxDate="MAX_MINOR_BIRTH_DATE"
        />
      </Form>
      <HMButton
        class="w-full"
        :class="{ 'mt-10': formHasError, 'mt-25': !formHasError }"
        :busy="isFetching"
        @click="handleSubmit"
        >{{ $t('account_recovery.forgot_username.submit_btn') }}</HMButton
      >
    </div>
  </RegistrationLayout>
</template>

<script>
import RegistrationLayout from '@components/layouts/RegistrationLayout'
import * as FormComponents from '../../common/forms'
import { required } from 'vuelidate/lib/validators'
import { getUserName } from '@platform-shared/services/member'
import { MAX_MINOR_BIRTH_DATE } from '@platform-shared/constants'

export default {
  components: { RegistrationLayout, ...FormComponents },
  data() {
    return {
      model: {
        firstName: '',
        lastName: '',
        memberNumber: '',
        birthday: null,
      },
      error: null,
      isFetching: false,
    }
  },
  validations: {
    model: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      memberNumber: {
        required,
      },
      birthday: {
        required,
      },
    },
  },
  created() {
    this.MAX_MINOR_BIRTH_DATE = MAX_MINOR_BIRTH_DATE
  },
  methods: {
    back() {
      this.$router.back()
    },
    onSuccess(user) {
      this.$router.push({
        name: 'account-found',
        params: { initialUsername: user },
      })
    },
    handleSubmit() {
      this.error = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isFetching = true
        const memberInfo = {
          lastName: this.model.lastName,
          firstName: this.model.firstName,
          birthday: this.model.birthday.toISOString().substring(0, 10),
          memberNumber: this.model.memberNumber,
        }
        getUserName(memberInfo)
          .then((response) => {
            if (response.data.registered) {
              this.isFetching = false
              this.onSuccess(response.data.username)
            } else {
              this.error = this.$t(
                'account_recovery.forgot_username.member_unregistered'
              )
              this.isFetching = false
            }
          })
          .catch(() => {
            this.error = this.$t(
              'account_recovery.forgot_username.member_not_found'
            )
            this.isFetching = false
          })
      }
    },
  },
  computed: {
    formHasError() {
      return !!this.error
    },
  },
}
</script>
