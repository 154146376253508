import _every from 'lodash/every'

const password = {
  data() {
    return {
      model: {
        username: '',
        password: '',
        confirm: '',
      },
    }
  },
  computed: {
    passwordRequirements() {
      return [
        {
          text: 'registration.password.chars',
          isPassing:
            this.model.password.length >= 8 &&
            this.model.password.length <= 500,
        },
        {
          text: 'registration.password.upper',
          isPassing: this.model.password.search('[A-Z]') !== -1,
        },
        {
          text: 'registration.password.lower',
          isPassing: this.model.password.search('[a-z]') !== -1,
        },
        {
          text: 'registration.password.number',
          isPassing: this.model.password.search('[0-9]') !== -1,
        },
        {
          text: 'registration.password.special',
          isPassing: this.model.password.search('[(!@#%^&*)]') !== -1,
        },
      ]
    },
    meetsPasswordRequirements() {
      return _every(this.passwordRequirements, 'isPassing')
    },
  },
}
export default password
