<template>
  <RegistrationLayout :showContact="false">
    <Title class="text-center">{{
      $t('account_recovery.reset_password.reset_password')
    }}</Title>

    <div class="w-full flex justify-center">
      <Form :validator="$v.model" :error="error">
        <FormInput
          v-model="model.email"
          :label="$t('account_recovery.reset_password.username')"
          name="username"
          placeholder=""
          type="email"
          class="mb-20"
        />

        <FormInput
          v-model="model.password"
          :label="$t('account_recovery.reset_password.new_password')"
          name="password"
          placeholder="••••••••"
          secure
          class="mt-20 mb-10"
          type="password"
        />
        <PasswordRequirements :reqs="passwordRequirements" />
        <FormInput
          v-model="model.confirm"
          :label="$t('account_recovery.reset_password.confirm_password')"
          name="confirm"
          placeholder="••••••••"
          secure
          class="mt-12"
          type="password"
        />
      </Form>
    </div>
    <div class="flex justify-center mt-40">
      <HMButton
        :busy="changePasswordFromResetIsFetching"
        :disabled="!formIsValid"
        @click="handleSubmit"
        >{{ $t('account_recovery.reset_password.submit_btn') }}
      </HMButton>
    </div>
  </RegistrationLayout>
</template>

<script>
import RegistrationLayout from '@components/layouts/RegistrationLayout'
import Title from '../../common/Typography/Title'
import * as FormComponents from '../../common/forms'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import password from '@platform-shared/mixins/password'
import PasswordRequirements from '@components/common/PasswordRequirements'

export default {
  components: {
    Title,
    RegistrationLayout,
    ...FormComponents,
    PasswordRequirements,
  },
  mixins: [password],
  props: {
    focused: {
      type: Boolean,
    },
  },
  data() {
    return {
      model: {
        email: '',
      },
      error: null,
      token: null,
    }
  },
  validations: {
    model: {
      email: {
        required,
      },
      password: {
        required,
      },
      confirm: {
        required,
        matching() {
          return this.model.password === this.model.confirm
        },
      },
    },
  },
  computed: {
    ...mapGetters('registration', ['changePasswordFromResetIsFetching']),
    formHasError() {
      return !!this.error
    },
    formIsValid() {
      return !this.$v.model.$invalid && this.meetsPasswordRequirements
    },
  },
  methods: {
    ...mapActions('registration', ['changePasswordFromReset']),
    async handleSubmit() {
      this.$v.$touch()
      if (!this.$v.model.$invalid && this.meetsPasswordRequirements) {
        try {
          await this.changePasswordFromReset({
            username: this.model.email,
            oldPassword: this.$route.query.pw,
            newPassword: this.model.password,
          })
          this.$router.push('/reset-password-success')
        } catch (error) {
          const { message } = error.json
          this.error = message
        }
      }
    },
  },
}
</script>
