<template>
  <RegistrationLayout>
    <div class="w-300 mx-auto">
      <Title class="font-normal text-center">{{
        $t('account_recovery.reset_password_success.success')
      }}</Title>
      <div class="text-center w-9/12 m-auto">
        <img src="../../../assets/images/Cloud_Check.png" class="my-8" />
      </div>
      <H4 class="text-center break-all py-10">{{
        $t('account_recovery.reset_password_success.description')
      }}</H4>
      <Body class="text-center break-all py-10">{{ username }}</Body>
      <HMButton
        textSize="large"
        fontWeight="normal"
        size="large"
        class="w-full mt-10"
        @click="$router.push('/login')"
        >{{
          $t('account_recovery.reset_password_success.back_to_signin')
        }}</HMButton
      >
    </div>
  </RegistrationLayout>
</template>

<script>
import RegistrationLayout from '@components/layouts/RegistrationLayout'

export default {
  components: { RegistrationLayout },
  props: ['username'],
}
</script>
