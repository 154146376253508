<template>
  <div>
    <div
      v-for="(req, key) in reqs"
      :key="key"
      class="mt-8"
      :class="{ 'text-green-500': req.isPassing }"
    >
      <i
        :aria-label="
          req.isPassing ? $t('common.passing') : $t('common.not_passing')
        "
        class="ml-16 mr-8 text-grey-500"
        :class="{
          'text-green-500 fas fa-check': req.isPassing,
          'fas fa-minus': !req.isPassing,
        }"
      />
      <span>{{ $t(req.text) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reqs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style></style>
