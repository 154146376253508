<template>
  <RegistrationLayout>
    <div class="w-300 mx-auto">
      <Title class="font-normal text-center">{{
        $t('account_recovery.account_found.title')
      }}</Title>
      <div class="text-center w-9/12 m-auto">
        <img
          src="../../../assets/images/Cloud_Check.png"
          class="my-8 w-250 mx-auto"
        />
      </div>
      <H4 class="text-center break-all py-10">{{
        $t('account_recovery.account_found.description')
      }}</H4>
      <Body class="text-center break-all py-10">{{ initialUsername }}</Body>
      <HMButton
        size="large"
        textSize="large"
        fontWeight="normal"
        class="w-full mt-10"
        @click="$router.push({ name: 'login', params: { initialUsername } })"
        >{{ $t('account_recovery.account_found.signin_btn') }}</HMButton
      >
    </div>
  </RegistrationLayout>
</template>

<script>
import RegistrationLayout from '@components/layouts/RegistrationLayout'

export default {
  components: { RegistrationLayout },
  props: ['initialUsername'],
}
</script>
