<template>
  <RegistrationLayout>
    <div class="md:w-11/12 md:mx-auto">
      <div class="flex justify-center">
        <img
          src="../../../assets/images/Sent.png"
          width="150"
          class="mt-20 mb-20"
        />
      </div>
      <Title class="text-center mt10">{{
        $t('account_recovery.email_sent.request_sent')
      }}</Title>
      <Body v-if="resent" class="text-center">{{
        $t('account_recovery.email_sent.email_resent')
      }}</Body>
      <Body class="text-center">{{
        $t('account_recovery.email_sent.check_email')
      }}</Body>

      <FormError v-if="!!error" :text="error" />

      <div class="flex justify-center mt-40">
        <HMButton @click="$router.push('/login')">
          {{ $t('account_recovery.email_sent.back_to_signin') }}
        </HMButton>
      </div>

      <div v-if="data" class="mt-20 mb-20 text-center" @click="resendEmail">
        <h5 class="inline-block border-b-2 border-black cursor-pointer">
          {{ $t('account_recovery.email_sent.email_not_received') }}
        </h5>
      </div>
    </div>
  </RegistrationLayout>
</template>

<script>
import RegistrationLayout from '@components/layouts/RegistrationLayout'
import Title from '../../common/Typography/Title'
import { mapActions } from 'vuex'

export default {
  components: { Title, RegistrationLayout },
  props: ['data'],
  data: () => ({
    resent: false,
    error: null,
  }),
  methods: {
    ...mapActions('registration', ['resetPassword']),
    async resendEmail() {
      try {
        if (this.data) {
          await this.resetPassword(this.data)
          this.resent = true
        }
      } catch (error) {
        const { message } = error.json
        this.error = message
      }
    },
  },
}
</script>
